import React from 'react';
import { StaticQuery,graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Image from "gatsby-image";
import { Carousel } from "react-responsive-carousel";
import HeaderName from "../../svg/almaRu.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Sort from '../../components/SortGallery';

const sortA = []
const Intro = ({dataPack}) => (

    <section className={`${dataPack.allJson.edges[0].node.headerData[0].headerSection}`} id={`${dataPack.allJson.edges[0].node.headerData[0].headerLink}`}>
      <Container fluid className="textScreen" >
        <Row noGutters className="static-header">
          <Col xl={1} lg={1} md={1} className="my-horLine d-md-flex align-content-center flex-wrap d-none header-abs">
          <HeaderName/>
          </Col>
          <Col sm={12}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              useKeyboardArrows
              infiniteLoop autoPlay
            >

             {dataPack.allMarkdownRemark.edges[0].node.frontmatter.slide.map((items,i)=>(
               <div>
              <div className="my-slide">
                <Image
                  alt={items.alt}
                  fluid={items.img.childImageSharp.fluid}
                />
              </div>
              <div className="my-text">
                <Container fluid>
                  <Row no-gutters>
                    <Col lg={1} md={1}>

                    </Col>
                    <Col lg={7} md={6} className="d-none d-md-block">

                    </Col>
                    <Col lg={4} md={5} sm={12} className="my-sloganLine d-flex align-content-center flex-wrap">
                      <h3 className="sloganPara">
                        <div dangerouslySetInnerHTML={{ __html: items.text}}/>
                      </h3>
                      <div className="gallery" id="intro">
                        <Sort grid="react-photo-gallery--gallery" gridItem="grid-item" layoutM="fitRows" classRow="d-flex align-items-center justify-content-center" classCol="col-md col-sm-12 d-flex justify-content-center no-padding gallery-intro"
                              oneItem={items.no} noItem={i} sortArray={
                                  dataPack.allMarkdownRemark.edges[0].node.frontmatter.slide.map((items,j)=>(
                                    sortA[j] = [items.nameLink, {filter: items.sortLink}]
                                  ))
                                }
                              colHrefName={items.no==="6"?"candel":"screen_gal"}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={5} sm={12} className="d-flex align-content-center flex-wrap">
                    </Col>
                  </Row>
                </Container>
              </div>
              </div>
              ))}

            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  )

export default props =>(
<StaticQuery query={graphql`
  query introQueryRu{
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/intro/ru/" } }
    ) {
      edges {
        node {
          excerpt(format: HTML, pruneLength: 1000)
          frontmatter {
            slide{
                text
                nameLink
                sortLink
                no
                alt
                img{
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    allJson(sort: {fields: headerData}, limit: 1) {
      edges {
        node {
          headerData{
              headerName
              headerLink
              headerSection
              headerImage{
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
`}
render={data=><Intro dataPack={data}{...props} />}
/>
)
